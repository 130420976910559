export function cleanUrl(url: string): string | undefined {
    if (isFinnboxURL(url)) {
        return cleanFinnboxURL(url);
    } else if (isInnfinnURL(url)) {
        return cleanInnfinnURL(url);
    } else if (isRedirectAppURL(url)) {
        return cleanRedirectAppURL(url);
    } else {
        return;
    }
}

function isFinnboxURL(url: string): boolean {
    return url.includes('/messages');
}

function isInnfinnURL(url: string): boolean {
    return url.includes('/innfinn');
}

function isRedirectAppURL(url: string): boolean {
    const re = /www\.finn\.no\/[0-9]+$/;
    return re.test(url);
}

function cleanFinnboxURL(url: string): string {
    url = url.replace(/(\/messages\/new)\/[0-9]+$/, '$1/adId');
    url = url.replace(/(\/messages\/id)\/.*$/, '$1/ID');
    return url;
}

function cleanInnfinnURL(url: string): string {
    return url.replace(/(\/innfinn\/adselection\/ad)\/[0-9]+/, '$1/adId');
}

function cleanRedirectAppURL(url: string): string {
    return url.replace(/(www\.finn\.no)\/[0-9]+$/, '$1/adId');
}
